.forme {
    background-color: white;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
}

.forme1 {
    background-color: white;
    border-radius: 10px;
    margin-left: 4px;
    margin-right: 4px;
}

.borderForme2{
    border: solid;
    border-width: 0px;
    border-color: white;
    border-left-color: #fc0362;
    border-left-width: 2px;
}
.borderForme3{
    border: solid;
    border-width: 0px;
    border-color: white;
    border-left-color: #079220;
    border-left-width: 2px;
}
.borderForme4{
    border: solid;
    border-width: 0px;
    border-color: white;
    border-left-color: #0398fc;
    border-left-width: 2px;
}

.block{
    display: block;
    display: inline;
}

.iconeCirculaire {
    font-size: 40px;
    border-radius: 30px;
}

.iconeCirculaire1 {
    font-size: 35px;
    border-radius: 35px;
}

.iconeCarre{
    font-size: 50px;
    border-radius: 10px;
}

.fontsize{
    font-size: 10px;
}

.c1 {
    color: #4f4cdb;
    background-color: rgba($color: #4f4cdb, $alpha: .2);
}


.c2{
    color: #fc0362;
    //background-color: rgba($color: #fc0362, $alpha: .2);
}
.borderC2{
    border: solid;
    border-width: 1px;
    border-color: #fc0362;
}
.borderBottom2{
    border: solid;
    border-width: 0px;
    border-color: white;
    border-bottom-color: #fc0362;
    border-bottom-width: 3px;
}


.c3{
    color: #079220;
    //background-color: rgba($color: #079220, $alpha: .2);
}
.borderC3{
    border: solid;
    border-width: 1px;
    border-color: #079220;
}
.borderBottom3{
    border: solid;
    border-width: 0px;
    border-color: white;
    border-bottom-color: #079220;
    border-bottom-width: 3px;
}


.c4{
    color: #0398fc;
    //background-color: rgba($color: #0398fc, $alpha: .2);
}
.borderC4{
    border: solid;
    border-width: 1px;
    border-color: #0398fc;
}
.borderBottom4{
    border: solid;
    border-width: 0px;
    border-color: white;
    border-bottom-color: #0398fc;
    border-bottom-width: 3px;
}

.progress-bar {  
    background-color: rgba($color: #0398fc, $alpha: 1);
    color: #fff;
    font-size: 10px;
    
    
  }

.couleur {
    background-color: green;
}


